import revive_payload_client_WKYyydgO35 from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5__3pdhant4aktqb3wul2d64ee3uu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_tgvU5Tkcew from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5__3pdhant4aktqb3wul2d64ee3uu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_0o06Qik5EB from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5__3pdhant4aktqb3wul2d64ee3uu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_3tPpsg2sok from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5__3pdhant4aktqb3wul2d64ee3uu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_h3QUPJwZD2 from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5__3pdhant4aktqb3wul2d64ee3uu/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_zvK1MbPrrp from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5__3pdhant4aktqb3wul2d64ee3uu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_s09IbIubW6 from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5__3pdhant4aktqb3wul2d64ee3uu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_mvj85xSm4B from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/@pinia+nuxt@0.5.5_magicast@0.3.5_typescript@5.8.2_vue@3.5.13_typescript@5.8.2_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/runner/work/hopeai-official/hopeai-official/.nuxt/components.plugin.mjs";
import prefetch_client_QfYXnD0CRM from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt@3.15.4_@parcel+watcher@2.5.1_@types+node@22.13.9_db0@0.3.1_ioredis@5.6.0_magicast@0.3.5__3pdhant4aktqb3wul2d64ee3uu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_j8sTPutwxt from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxt-gtag@3.0.2_magicast@0.3.5/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_F8kaEBsWfz from "/home/runner/work/hopeai-official/hopeai-official/node_modules/.pnpm/nuxtjs-naive-ui@1.0.2_magicast@0.3.5_vue@3.5.13_typescript@5.8.2_/node_modules/nuxtjs-naive-ui/dist/runtime/plugin.mjs";
import templates_plugin_c3ZSpGJajK_XGAiiZ85rg from "/home/runner/work/hopeai-official/hopeai-official/.nuxt/templates.plugin.c3ZSpGJajK.mts";
import wow_RYu4ocOzj5 from "/home/runner/work/hopeai-official/hopeai-official/plugins/wow.ts";
export default [
  revive_payload_client_WKYyydgO35,
  unhead_tgvU5Tkcew,
  router_0o06Qik5EB,
  payload_client_3tPpsg2sok,
  navigation_repaint_client_h3QUPJwZD2,
  check_outdated_build_client_zvK1MbPrrp,
  chunk_reload_client_s09IbIubW6,
  plugin_vue3_mvj85xSm4B,
  components_plugin_KR1HBZs4kY,
  prefetch_client_QfYXnD0CRM,
  plugin_client_j8sTPutwxt,
  plugin_F8kaEBsWfz,
  templates_plugin_c3ZSpGJajK_XGAiiZ85rg,
  wow_RYu4ocOzj5
]